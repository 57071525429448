import { Container, Col, Row, Figure } from "react-bootstrap";

import KitchenFlowers from "../assets/kitchen-flowers-on-table.jpg"

const heading = "Byggfirma som renoverar med hög kvalité och inom rimlig tid.";
const lead = "Det ska kännas tryggt och säkert när man ska renovera."

const Jumbotron = () => (
    <Container className="px-5 mb-3">
        <Row className="pt-5 gx-5 align-items-center">
            <Col lg="6">
                <h1 className="pb-3 display-5">{heading}</h1>
                <p className="lead">{lead}</p>
            </Col>
            <Col lg="6">
                <Figure>
                    <Figure.Image alt="Kitchen with flowers on the table" src={KitchenFlowers} className="img-fluid shadow rounded rounded-3" />
                    <Figure.Caption>En av de senaste renoveringar vi gjort.</Figure.Caption>
                </Figure>
            </Col>
        </Row>
    </Container>
);

export default Jumbotron;
