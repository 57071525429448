import { Container, Col, Row, Figure } from "react-bootstrap";

import Jumbotron from "../../components/jumbotron";
import ServiceCard from "../../components/serviceCard";

import bathroom from "../../assets/bathroom.jpg";
import kitchen from "../../assets/kitchen.jpg";

const backgoundTile = {
    backgroundImage: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(102,102,102,0) 35%, rgba(0,0,0,1) 100%)`,
    backgroundBlendMode: 'soft-light'
}

const Home = () => (
    <>
        <Jumbotron />

        <Container className="my-5 p-5 bg-info-subtle" style={backgoundTile}>
            <Row>
                <Col>
                    <h2 className="mb-3">Din renovering ska bli rätt.</h2>
                    <p className="lead mb-5">
                        Boka ett möte med oss så berättar vi hur gör din renovering både trygg och säker.
                    </p>
                    <a href="https://forms.gle/qaQzjY9XPiH8G5jR7" className="btn btn-primary btn-lg" target="blank">Boka ett möte med oss</a>
                </Col>
            </Row>
        </Container>

        <Container className="p-5">
            <Row className="gy-3 flex-lg-row-reverse">
                <Col lg="6">
                    <Figure>
                        <Figure.Image alt="bathroom" src={bathroom} className="img-fluid rounded shadow" />
                    </Figure>
                </Col>
                <Col lg="6" className="align-self-center position-relative">
                    <h2>Dags för ett nytt badrum?</h2>
                    <p className="lead mb-5">Det kan vi ordna och mycket mer.</p>
                    <p><a href="mailto:info@xqlusive.se" rel="noreferrer" target="_blank" className="stretched-link btn btn-outline-secondary">Få en offert här</a></p>
                </Col>
            </Row>
        </Container>

        <Container className="p-5">
            <Row className="gy-3">
                <Col lg="6">
                    <Figure>
                        <Figure.Image src={kitchen} alt="kitchen" className="img-fluid rounded shadow" />
                    </Figure>
                </Col>
                <Col lg="6" className="align-self-center position-relative">
                    <h2 className="lh-1 mb-3">Dags för drömköket?</h2>
                    <p className="mb-5 lead">Det kan vi ordna och mycket mer.</p>
                    <p><a href="mailto:info@xqlusive.se" rel="noreferrer" target="_blank" className="stretched-link btn btn-outline-secondary">Få en offert här</a></p>
                </Col>
            </Row>
        </Container>

        <Container className="p-5 mb-3">
            <Row>
                <Col lg="12" className="text-lg-center mb-4">
                    <h2>Fler tjänster vi erbjuder för att kunna utföra din renovering.</h2>
                </Col>
            </Row>
            <Row className="row-cols-1 row-cols-sm-1 row-cols-md-2 g-4">
                <Col>
                    <ServiceCard
                        heading="Målning"
                        content="Vare sig du behöver hjälp med målning av väggar, tak eller tapeter är vi där för att hjälpa dig uppnå resultatet du är ute efter."
                    />
                </Col>
                <Col>
                    <ServiceCard
                        heading="Snikeri"
                        content="Ibland måste man göra special lösningar vare sig det handlar om att bygga väggar eller lägga ny parkett eller montering av kök."
                    />
                </Col>
                <Col>
                    <ServiceCard
                        heading="El"
                        content="I nya renoveringar ingår oftast byte av uttag, strömbrytare och dragning av ny el-kablage med mera."
                    />
                </Col>
            </Row>
        </Container>
    </>
);

export default Home;
