import { Container, Navbar } from "react-bootstrap";

import logo from "../assets/page-logo.png";

const Navigation = () => (
    <Navbar bg="light">
        <Container>
            <Navbar.Brand><img src={logo} alt="Xqlusive" className="img-fluid" width="100" height="40" /></Navbar.Brand>
        </Container>
    </Navbar>
);

export default Navigation;
