
import Navigation from "../components/navigation";
import SiteFooter from "../components/siteFooter";

const Layout = ({ children }) => (
    <>
        <Navigation />
        {children}
        <SiteFooter />
    </>
);

export default Layout;
