import { Container } from "react-bootstrap";

import LogoInv from "../assets/page-logo-inv.png";

const dateObj = new Date();
const currentYear = dateObj.getFullYear();

const SiteFooter = () => (
    <footer className="bg-dark">
        <Container className="text-bg-dark px-5">
            <div className="row pt-4">
                <div className="col-md-4">
                    <div className="mb-4">
                        <img src={LogoInv} alt="Xqlusive" className="img-fluid" width="100" height="40" />
                    </div>
                    Kontakta oss på <a href="mailto:info@xqlusive.se" className="link-info">info@xqlusive.se</a>
                </div>
            </div>
            <div className="border-top border-secondary border-opacity-50 mb-4 mt-5"></div>
            <div className="d-flex flex-wrap justify-content-between align-items-center pb-5">
                <div className="col-md-4 d-flex align-items-center">
                    <small className="text-center">&copy; 2021 - {currentYear} Xqlusive</small>
                </div>
                <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li className="ms-3">
                        <a href="https://www.instagram.com/xqlusivebygg" className="text-white-50 lead"><i className="fa-brands fa-instagram" title="Instagram"></i></a>
                    </li>
                    <li className="ms-3">
                        <a href="https://www.facebook.com/exclusivebygg" className="text-white-50 lead"><i className="fa-brands fa-facebook" title="Facebook"></i></a>
                    </li>
                </ul>
            </div>
        </Container>
    </footer>
);

export default SiteFooter;
