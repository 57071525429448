import { Card } from "react-bootstrap";

const ServiceCard = ({heading, content}) => (
    <Card className="text-bg-light">
        <Card.Body>
            <Card.Title>{heading}</Card.Title>
            <Card.Text>
                {content}
            </Card.Text>
        </Card.Body>
    </Card>
);

export default ServiceCard;
